import { isMobileOnly } from "react-device-detect";
import { motion } from "framer-motion";
import React from "react";
import loadable from "@loadable/component";
import { subHero } from "../../data/whitepapers/restaurants-hub";
import useStartMotion from "../../hooks/use-start-motion";

const NoVisualsCustom = loadable(() =>
  import("../../components/NoVisuals/NoVisualsCustom")
);

const slideVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const NoVisuals = () => {
  const { ref, controls } = useStartMotion();

  return (
    <motion.section
      className="max-w-1440 z-1 relative lg:mt-20"
      ref={ref}
      initial={isMobileOnly ? "visible" : "hidden"}
      variants={slideVariants}
      animate={controls}
      transition={{ duration: 0.8, delay: 0.2 }}
    >
      <NoVisualsCustom sectionData={subHero} complex leftCta openInNewTab />
    </motion.section>
  );
};

export default NoVisuals;
